import {
  AsyncThunk,
  createAsyncThunk,
  createSelector,
  createSlice,
} from '@reduxjs/toolkit';
import { find } from 'lodash';
import { Basket, BasketLine, ProductInfo } from 'models';
import {
  addToBasket,
  getPaymentDetails,
  deleteBasketLine,
  getAllBasketLines,
  updateBasketLine,
  getNewBasket,
  getCurrentBasket,
  deleteBasket,
  saveBasketApiInput,
  saveBasket,
  AddLinePayload,
  UpdateLinePayload,
  populateBasket,
  addVoucherToBasket,
  toggleOneOffSubscriptionApi,
  ToggleOneOffSubscriptionPayload,
  addMultipleToBasket,
} from 'services/basket.api';
import {
  clearTrackedCartInSolve,
  trackCartInSolve,
} from '../utils/solve_analytics';
import { RootState } from './store';

export interface BasketState {
  lines: BasketLine[];
  basket?: Basket;
  paymentDetail: '',
  products: ProductInfo[];
  defaultProducts: ProductInfo[];
  voucher?: string;
  isLoading: boolean;
  error?: string;
  invalidVoucher?: boolean;
}

type GenericAsyncThunk = AsyncThunk<unknown, unknown, any>;
type PendingAction = ReturnType<GenericAsyncThunk['pending']>;
type RejectedAction = ReturnType<GenericAsyncThunk['rejected']>;
type FulfilledAction = ReturnType<GenericAsyncThunk['fulfilled']>;

const initialState: BasketState = {
  lines: [],
  basket: undefined,
  products: [],
  defaultProducts: [
    {
      title: 'Chicken Dog Box',
      id: 7,
      price: {
        incl_tax: 59,
      },
      product_class: 'Subscription Box',
      quantity: 1,
    },
  ],
  voucher: undefined,
  isLoading: false,
  invalidVoucher: false,
  error: undefined,
};

// Basket thunks
export const fetchCurrentBasket = createAsyncThunk(
  'basket/fetchCurrentBasket',
  async (_, { rejectWithValue }) => {
    const { data, error } = await getCurrentBasket();
    if (error) return rejectWithValue(error);
    trackCartInSolve(data.id);
    return data;
  },
);

// export const fetchPaymentDetailFormSessionId = createAsyncThunk(
//     'basket/fetchPaymentDetailFormSessionId',
//     async (params, { rejectWithValue,  }) => {
//       const { data, error } = await getPaymentDetails(params);
//       if (error) return rejectWithValue(error);
//       return data;
//     },
//   );


export const fetchPaymentDetailFormSessionId = createAsyncThunk(
  'basket/fetchPaymentDetailFormSessionId',
  async (data, { rejectWithValue,  }) => {
    try {
      const orderData = await getPaymentDetails(data);
      console.log("fetchPaymentDetailFormSessionId", {orderData})
      return orderData;
    } catch (error) {
      return rejectWithValue(error);
    }
  },
);
  

export const createNewBasket = createAsyncThunk(
  'basket/createNewBasket',
  async (_, { rejectWithValue }) => {
    const { data, error } = await getNewBasket();
    if (error) return rejectWithValue(error);
    trackCartInSolve(data.id);
    return data;
  },
);

export const updateBasket = createAsyncThunk(
  'basket/updateBasket',
  async (basketData: saveBasketApiInput, { rejectWithValue, dispatch }) => {
    const { error } = await saveBasket(basketData);
    if (error) return rejectWithValue(error);
    //await dispatch(fetchCurrentBasket());
    return;
  },
);

export const removeBasket = createAsyncThunk(
  'basket/removeBasket',
  async (_, { rejectWithValue, getState }) => {
    const { basket: basketState } = getState() as RootState;
    const { data: currentBasket } = await getCurrentBasket();
    const currentBasketId = basketState.basket?.id || currentBasket?.id;
    if (currentBasketId) {
      const { error } = await deleteBasket(currentBasketId);
      if (error) return rejectWithValue(error);
      clearTrackedCartInSolve();
      return true;
    } else {
      return rejectWithValue('no basket available');
    }
  },
);

export const fetchBasketByToken = createAsyncThunk(
  'basket/fetchBasketByToken',
  async (tokenId: string, { rejectWithValue }) => {
    const { data, error } = await populateBasket(tokenId);
    if (error) return rejectWithValue(error);
    trackCartInSolve(data.id);
    return data;
  },
);

// TODO Better if backend returns basket after applying the voucher
export const applyVoucherToBasket = createAsyncThunk(
  'basket/applyVoucherToBasket',
  async (voucherId: string, { rejectWithValue }) => {
    const { data, error } = await addVoucherToBasket(voucherId);
    if (error) return rejectWithValue(error);
    return voucherId;
  },
);

// Lines thunks
export const fetchLines = createAsyncThunk(
  'basket/fetchLines',
  async (_, { rejectWithValue, getState }) => {
    const {
      basket: basketState,
      products: { entities = [] },
    } = getState() as RootState;
    if (basketState.basket?.id) {
      const { data, error } = await getAllBasketLines(basketState.basket.id);
      if (error) return rejectWithValue(error);
      return { lines: data, products: entities };
    } else {
      return rejectWithValue('no basket available');
    }
  },
);

export const addLine = createAsyncThunk(
  'basket/addLine',
  async (lineData: AddLinePayload, { rejectWithValue, dispatch }) => {
    const { data, error } = await addToBasket(lineData);
    // TODO we need to think if backend can return the new line along with  basket.
    if (!lineData.skipFetchLines) {
      await dispatch(fetchCurrentBasket());
      await dispatch(fetchLines());
    }

    if (error) return rejectWithValue(error);
    return data;
  },
);
export const addMultipleLine = createAsyncThunk(
  'basket/addMultipleLine',
  async (lineData: AddLinePayload[], { rejectWithValue, dispatch }) => {
    const { data, error } = await addMultipleToBasket(lineData);
    // TODO we need to think if backend can return the new line along with  basket.
    // if (!lineData.skipFetchLines) {
    await dispatch(fetchCurrentBasket());
    await dispatch(fetchLines());
    // }

    if (error) return rejectWithValue(error);
    return data;
  },
);

export const toggleOneOffSubscription = createAsyncThunk(
  'basket/toggleOneOffSubscription',
  async (
    payload: ToggleOneOffSubscriptionPayload,
    { rejectWithValue, dispatch },
  ) => {
    if (payload.basketId) {
      const { data, error } = await toggleOneOffSubscriptionApi(payload);
      await dispatch(fetchCurrentBasket());
      await dispatch(fetchLines());
      if (error) return rejectWithValue(new Error('Set Subscription failed'));
      return data;
    }
  },
);

export const updateLine = createAsyncThunk(
  'basket/updateLine',
  async (
    lineData: UpdateLinePayload,
    { rejectWithValue, getState, dispatch },
  ) => {
    const { basket: basketState } = getState() as RootState;
    const basketId = basketState.basket?.id;
    if (basketId) {
      const { data, error } = await updateBasketLine({ ...lineData, basketId });
      if (error) return rejectWithValue(error);
      await dispatch(fetchCurrentBasket());
      await dispatch(fetchLines());
      return data;
    } else {
      return rejectWithValue('no basket available');
    }
  },
);

export const removeLine = createAsyncThunk(
  'basket/removeLine',
  async (lineId: number, { rejectWithValue, getState, dispatch }) => {
    const state = getState() as RootState;
    // Important TODO backend should return the basket here.
    const { error } = await deleteBasketLine(lineId, state.basket.basket?.id);
    await dispatch(fetchCurrentBasket());
    await dispatch(fetchLines());
    if (error) return rejectWithValue(error);
    return lineId;
  },
);

const basketSlice = createSlice({
  name: 'basket',
  initialState,
  reducers: {
    resetInvalidVoucher: state => {
      state.invalidVoucher = false;
      return state;
    },
    resetBasket: state => {
      state.basket = undefined;
      state.lines = [];
      return state;
    },
  },
  extraReducers: builder => {
    // basket reducers
    builder
      .addCase(fetchCurrentBasket.fulfilled, (state, action) => {
        state.basket = action.payload;
      })
      .addCase(fetchPaymentDetailFormSessionId.fulfilled, (state, action) => {
        state.paymentDetail = action.payload;
      })
      .addCase(fetchBasketByToken.fulfilled, (state, action) => {
        state.basket = action.payload;
      })
      .addCase(createNewBasket.fulfilled, (state, action) => {
        state.basket = action.payload;
      })
      .addCase(removeBasket.fulfilled, _ => initialState)
      .addCase(applyVoucherToBasket.fulfilled, (state, action) => {
        state.voucher = action.payload;
        state.invalidVoucher = false;
      })
      .addCase(applyVoucherToBasket.rejected, (state, action) => {
        state.invalidVoucher = true;
      })

      // lines reducers
      .addCase(fetchLines.fulfilled, (state, action) => {
        const { lines, products = [] } = action.payload;
        // Ensure lines is always an array
        const allLines = Array.isArray(lines) ? lines : [lines].filter(Boolean);
        state.lines = allLines;
        const currentProducts = [] as ProductInfo[];
        allLines.forEach(lineItem => {
          const productData = find(products, {
            url: lineItem.product,
          }) as ProductInfo;
          if (productData) {
            currentProducts.push({
              ...productData,
              quantity: lineItem.quantity,
            } as ProductInfo);
          }
        });
        state.products = currentProducts;
      })
      .addCase(addLine.fulfilled, (state, action) => {
        state.basket = action.payload;
      })
      .addCase(addMultipleLine.fulfilled, (state, action) => {
        state.basket = action.payload;
      })
      .addCase(toggleOneOffSubscription.fulfilled, (state, action) => {
        state.basket = action.payload;
      })
      .addCase(updateLine.fulfilled, (state, action) => {
        const updatedLine = action.payload;
        state.lines = state.lines.map(line => {
          if (line.id === updatedLine.id) {
            line = updatedLine;
          }
          return line;
        });
      })
      .addCase(removeLine.fulfilled, (state, action) => {
        const removedLineId = action.payload;
        state.lines = state.lines.filter(line => line.id !== removedLineId);
        if (state.basket) {
          state.basket.line_ids = state.basket.line_ids.filter(
            id => id !== removedLineId,
          );
        }
      }) // handle loading status globally for all the actions
      .addMatcher(
        (action): action is RejectedAction =>
          action.type.startsWith('basket') && action.type.endsWith('/rejected'),
        state => {
          //TODO Have to handle errors here if we want to show some message in component
          state.isLoading = false;
        },
      )
      .addMatcher(
        (action): action is PendingAction =>
          action.type.startsWith('basket') && action.type.endsWith('/pending'),
        state => {
          state.isLoading = true;
        },
      )
      .addMatcher(
        (action): action is FulfilledAction =>
          action.type.startsWith('basket') &&
          action.type.endsWith('/fulfilled'),
        state => {
          state.isLoading = false;
        },
      );
  },
});

// lines related Selectors

export const lines = (state: RootState) => state.basket.lines;

export const totalItemsCountSelector = createSelector(lines, lines => {
  return lines?.reduce((prev, line) => {
    const quantity = line.quantity || 0;
    prev += quantity;
    return prev;
  }, 0);
});

export const productIdsInBasketSelector = createSelector(lines, lines => {
  return lines
    .map(line => {
      const matches = line.product.match(/\/(\d+)\/$/);
      return (matches && matches[1]) || '';
    })
    .filter(id => !!id);
});

export const basketProductLineMapSelector = createSelector(lines, lines => {
  const productLineMap: { [key: string]: number[] } = {};

  lines.forEach(line => {
    const matches = line.product.match(/\/(\d+)\/$/);
    const productId = (matches && matches[1]) || '';
    if (!!productId) {
      if (productLineMap[productId]) {
        productLineMap[productId].push(line.id);
      } else {
        productLineMap[productId] = [line.id];
      }
    }
  });
  return productLineMap;
});

export const { resetInvalidVoucher, resetBasket } = basketSlice.actions;
export default basketSlice.reducer;
